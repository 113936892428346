import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
import loginStyles from 'components/login/styles';
import { IitControlledInput } from 'components/shared-components/iit-controlled-input';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import useCommonStyles, { themeColors } from 'style/common';
import { useCallback, useMemo, useState } from 'react';
import { AlertModalSeverity, InputType } from 'utils/enums';
import { UserService } from 'services/user.service';
import { NAVIGATION_PATHS } from 'utils/routes';
import { IAlertToaster } from 'utils/interfaces';
import { displayToaster } from 'utils/utils';
import { PasswordRules } from 'components/password-rules/PasswordRules';

const ResetPassword = () => {
   const [arePasswordRulesValidated, setArePasswordRulesValidated] = useState(false);

   const navigate = useNavigate();
   const classes = loginStyles();
   const utilsClasses = useCommonStyles();
   const { t } = useTranslation();
   const { control, handleSubmit, setError, watch } = useForm();
   const { code } = useParams<{ code: string }>();
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const email = searchParams.get('email');

   const handleResetPassword = handleSubmit(
      useCallback(
         async (data) => {
            if (!data || !code || !email) return;

            if (data?.confirmPassword !== data?.password) {
               setError('confirmPassword', {
                  message: t('GENERAL.PASSWORDS_MUST_BE_EQUALS').toString(),
               });
               return;
            }

            const success = await UserService.resetPassword({
               password: data?.password,
               code,
               email,
            });

            if (!success) return;

            displayToaster({
               message: t('GENERAL.PASSWORD_RESET_WITH_SUCCESS'),
               severity: AlertModalSeverity.SUCCESS,
            } as IAlertToaster);

            navigate(`/${NAVIGATION_PATHS.LOGIN}?email=${email}`);
         },
         [code, email, navigate, setError, t],
      ),
   );

   const FORM = useMemo(
      () => [
         {
            name: 'password',
            label: 'GENERAL.NEW_PASSWORD',
         },
         {
            name: 'confirmPassword',
            label: 'GENERAL.CONFIRM_NEW_PASSWORD',
         },
      ],
      [],
   );

   if (!code || !email) return <Navigate to={NAVIGATION_PATHS.LOGIN} />;
   return (
      <Container component="main" maxWidth="sm" className={utilsClasses.displayHeight}>
         <Box
            className={utilsClasses.height100}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
         >
            <Box display="flex" alignItems="start" width={1} marginBottom={6}>
               <Typography variant="h4">{t('GENERAL.RESET_PASSWORD')}</Typography>
            </Box>

            <Grid container spacing={2}>
               {FORM?.map(({ name, label }, index) => (
                  <Grid item xs={12} key={index}>
                     <IitControlledInput
                        name={name}
                        label={t(label)}
                        control={control}
                        type={InputType.PASSWORD}
                     />
                  </Grid>
               ))}

               <Grid item xs={12}>
                  <PasswordRules
                     password={watch('password')}
                     onRulesChecked={(checked: boolean) =>
                        setArePasswordRulesValidated(checked)
                     }
                  />
               </Grid>
            </Grid>

            <Button
               disableElevation
               size="large"
               type="submit"
               fullWidth
               variant="contained"
               color="primary"
               className={classes.submit}
               onClick={handleResetPassword}
               disabled={
                  !arePasswordRulesValidated ||
                  !watch('password') ||
                  !watch('confirmPassword')
               }
            >
               {t('GENERAL.CONFIRM')}
            </Button>

            <Box width={1}>
               <Divider sx={{ color: themeColors.lightDark }}>
                  {t('GENERAL.ALREADY_HAVE_AN_ACCOUNT')}
               </Divider>
            </Box>

            <Button
               size="medium"
               variant="text"
               color="primary"
               onClick={handleResetPassword}
               sx={{ marginTop: 4 }}
            >
               {t('GENERAL.LOGIN')}
            </Button>
         </Box>
      </Container>
   );
};

export default ResetPassword;
