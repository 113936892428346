import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const loginStyles = makeStyles((theme: Theme) => ({
   logo: {
      objectFit: 'contain',
      width: theme.spacing(25),
      height: theme.spacing(25),
   },
   submit: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      height: theme.spacing(7),
   },
}));

export default loginStyles;
