import { AxiosError } from 'axios';
import { t } from 'i18next';
import { AlertModalSeverity, AUTH_ENDPOINTS } from 'utils/enums';
import { IAlertToaster } from 'utils/interfaces';
import { displayToaster } from 'utils/utils';
import HTTP_CLIENT from './http-client';

export interface IUserInvitePayload {
   phoneNumber: string;
   firstName: string;
   surname: string;
   email: string;
   clientId: string;
   birthDate: string;
   isAdmin: boolean;
}

export interface IChangePasswordPayload {
   currentPassword: string;
   newPassword: string;
}

export const UserService = {
   sendUserInvite: async (payload: IUserInvitePayload) => {
      try {
         await HTTP_CLIENT.post(AUTH_ENDPOINTS.INVITE_USER, {
            ...payload,
            phoneNumber: Number(payload.phoneNumber),
         });
      } catch (e: unknown) {
         const errorData = (e as AxiosError)?.response?.data as { message: string };
         displayToaster({
            message: t(errorData?.message || 'GENERAL.SOMETHING_WENT_WRONG'),
            severity: AlertModalSeverity.ERROR,
         } as IAlertToaster);
      }
   },
   changePassword: async (payload: IChangePasswordPayload) => {
      try {
         await HTTP_CLIENT.post(AUTH_ENDPOINTS.CHANGE_PASSWORD, {
            ...payload,
         });
         displayToaster({
            message: t('AUTH.PASSWORD_CHANGED_WITH_SUCCESS'),
            severity: AlertModalSeverity.SUCCESS,
         } as IAlertToaster);
      } catch (e: unknown) {
         const errorData = (e as AxiosError)?.response?.data as { message: string };
         displayToaster({
            message: t(errorData?.message || 'GENERAL.SOMETHING_WENT_WRONG'),
            severity: AlertModalSeverity.ERROR,
         } as IAlertToaster);
      }
   },
   forgotPassword: async (email: string) => {
      try {
         await HTTP_CLIENT.get(
            `${AUTH_ENDPOINTS.RECOVER_PASSWORD}?email=${email.trim()}`,
         );
      } catch (e) {
         displayToaster({
            message: t('GENERAL.EMAIL_SENT_WITH_SUCCESS'),
            severity: AlertModalSeverity.ERROR,
         } as IAlertToaster);
      }
   },
   resetPassword: async (body: { password: string; code: string; email: string }) => {
      try {
         return await HTTP_CLIENT.post(AUTH_ENDPOINTS.DEFINE_PASSWORD, body);
      } catch (error) {
         const errorData = (error as AxiosError)?.response?.data as { message: string };
         displayToaster({
            message: t(errorData?.message || 'GENERAL.SOMETHING_WENT_WRONG'),
            severity: AlertModalSeverity.ERROR,
         } as IAlertToaster);
      }
   },
};
