export interface PropTypes {
   password: string;
   onRulesChecked: (isValid: boolean) => void;
}

export enum RULE {
   MIN_LENGTH = 'RULES.MIN_LENGTH',
   HAS_NUMBER = 'RULES.HAS_NUMBER',
   HAS_UPPERCASE = 'RULES.HAS_UPPERCASE',
   HAS_SPECIAL_CHAR = 'RULES.HAS_SPECIAL_CHAR',
}
