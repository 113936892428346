import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { REGEXP } from 'utils/constants';
import useRulesStyles from './PasswordRules.styles';
import { PropTypes, RULE } from './PasswordRules.types';

const PasswordRules: FC<PropTypes> = ({ password, onRulesChecked }) => {
   const { t } = useTranslation();
   const { valid, invalid, ul } = useRulesStyles();

   const RULES = useMemo(
      () => [
         { name: RULE.HAS_NUMBER, regex: REGEXP.HAS_NUMBER },
         { name: RULE.HAS_UPPERCASE, regex: REGEXP.HAS_UPPERCASE },
         { name: RULE.HAS_SPECIAL_CHAR, regex: REGEXP.HAS_SPECIAL_CHAR },
      ],
      [],
   );

   const isRuleValid = useCallback(
      (condition: RegExp) => {
         return condition.test(password) ? valid : invalid;
      },

      // eslint-disable-next-line react-hooks/exhaustive-deps
      [password],
   );

   const areAllRulesMet = useMemo(
      () => RULES.every((rule) => rule.regex.test(password)),
      [RULES, password],
   );

   useEffect(() => {
      onRulesChecked(areAllRulesMet);
   }, [areAllRulesMet, onRulesChecked]);

   return (
      <ul className={ul}>
         {!!password?.length &&
            RULES.map(({ name, regex }) => {
               return (
                  <li className={isRuleValid(regex)} key={name}>
                     {t(name)}
                  </li>
               );
            })}
      </ul>
   );
};

export { PasswordRules };
