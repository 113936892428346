import dayjs from 'dayjs';

import { REDUCE_COUNT } from './enums';
import { ArrayString, IAlertToaster, IProfile, PortalComponent } from './interfaces';
import { PubSub } from './pubsub';
import { ALERT } from './pubsub-topics';
import { themeColors } from '../style/common';
import { IUser } from 'components/users/user.types';
import { MutableRefObject } from 'react';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { RN_WEBVIEW_EVENTS } from './constants';
import { SchedulerDateTime } from '@devexpress/dx-react-scheduler';
import { AvailableOrdersStatus } from 'components/orders/types';
import { NAVIGATION_PATHS } from './routes';
import { ECollectOrdersStatus } from 'components/collect-orders/types';

export function dataGridDefaults() {
   // eslint-disable-next-line react-hooks/rules-of-hooks

   return {
      hideSortIcons: true,
      disableColumnMenu: true,
      minWidth: 200,
      width: 250,
      flex: 1,
   };
}

export const toFixed2Comma = (number: number) => {
   var formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
   });

   return formatter.format(number);
};

export const removeKeyFromObjectState = (key: string, { [key]: _, ...rest }) => rest;

export const reduceCount = (list: any[], property: REDUCE_COUNT) => {
   return list.reduce(
      (accumulator, currentValue) => accumulator + currentValue[property],
      0,
   );
};

export const importImage = (srcImage: string) => import(`../${srcImage}`);

export const importView = (view: PortalComponent) => {
   if (!view?.location) return;
   return import(`../${view.location}`).then((res) => {
      return {
         view: res.default,
         name: view?.location,
         position: view?.position,
         routing: view?.routing,
         menuOptions: view?.menuOptions,
      };
   });
};

export const getYearAndMonth = (date: string) => dayjs(date).format('YYYY/MM');

export const getInitials = (text: string) => {
   if (!text) return;

   const initials = text
      .split(' ')
      .map((n) => n[0])
      .join('')
      .substring(0, 2);

   return initials?.toUpperCase();
};

export const displayToaster = ({ message, severity }: IAlertToaster) => {
   const toaster: IAlertToaster = {
      message,
      severity,
      open: true,
   };

   PubSub.publishWithTopic(ALERT, 'error', toaster);
   return;
};

export const findRole = (roles?: string[], userRoles?: string[]) => {
   if (!userRoles || !roles) return false;
   return !!roles.find((role) => userRoles.includes(role));
};

export const scrollToBottom = (ref: MutableRefObject<HTMLDivElement | null>) => {
   ref?.current?.scrollIntoView({ behavior: 'smooth' });
};

export const genUniqueNumber = () => 'i' + new Date().getTime() + '';

export const parseDraftContentToRawJSON = (editorState: EditorState) => {
   return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
};

export const createDraftContentFromRawJSON = (rawJSON: string) => {
   return EditorState.createWithContent(convertFromRaw(JSON.parse(rawJSON)));
};

export const truncateString = (text: string, length: number) =>
   text.length > length ? `${text.slice(0, length)}...` : text;

export const getFulltimeFromMinutes = (minutes: number) => {
   const hours = Math.floor(minutes / 60);
   const remainingMinutes = minutes % 60;
   const seconds = 0;

   let time;
   if (hours >= 1) {
      time = dayjs().hour(hours).minute(0).second(0);
   } else {
      time = dayjs().hour(0).minute(remainingMinutes).second(seconds);
   }

   return time.format('HH:mm:ss');
};

export const listByPage = (page: number, orders: unknown[]) => {
   const end = page * 10;
   const start = end - 9 - 1;
   return orders.slice(start, end);
};

export const csvFileToArray = (string: string) => {
   const csvHeader = string
      .slice(0, string.indexOf('\n'))
      .replaceAll('\r', '')
      .replaceAll(' ', '')
      .split(',');
   const csvRows = string
      .slice(string.indexOf('\n') + 1)
      .replaceAll('\r', '')
      .split('\n')
      .filter((row) => !!row);

   return csvRows.map((i: string) => {
      const values = i.split(',');
      const obj = csvHeader.reduce(
         (object: ArrayString, header: string, index: number) => {
            object[header] = values[index];
            return object;
         },
         {},
      );
      return obj;
   });
};

/**
 *
 * @summary
 * Send information to parent view(react-native)
 */
export const sendEventToRnWebview = (payload: {
   value?: any;
   type: RN_WEBVIEW_EVENTS;
}) => {
   window?.ReactNativeWebView?.postMessage(JSON.stringify(payload));
};

export const parseUserToSelectItem = ({ firstName, surname, _id }: IUser) => {
   const parsedUser = {
      label: `${firstName} ${surname}`,
      _id,
   };

   return parsedUser;
};

export const getAppointmentColor = (
   status: ECollectOrdersStatus | 'LOOSE' | 'ASSOCIATED',
) => {
   const { success, warning, primary, secondary, error, grayLight } = themeColors;

   switch (status) {
      case ECollectOrdersStatus.CONFIRMED:
         return success.background;
      case ECollectOrdersStatus.UNCONFIRMED:
         return warning.background;
      case ECollectOrdersStatus.CANCELLED:
         return error.background;
      case 'LOOSE':
         return secondary;
      case 'ASSOCIATED':
         return primary;
      default:
         return grayLight;
   }
};

export const getTime = (date?: Date | string | number) => {
   if (!date) return;

   return dayjs(date).format('HH:mm');
};

export const getExtendedDate = (date?: Date | SchedulerDateTime) => {
   if (!date) return;

   return dayjs(date).format('dddd, MMMM YYYY - HH:mm');
};

export const getOrderStatusSettings = (status: AvailableOrdersStatus | string) => {
   switch (status) {
      case AvailableOrdersStatus.INITIAL:
         return {
            color: themeColors.neutral.background,
            label: 'COMPONENT_ORDERS.INITIAL',
         };
      case AvailableOrdersStatus.IN_PRODUCTION:
         return {
            color: themeColors.warning.background,
            label: 'COMPONENT_ORDERS.IN_PRODUCTION',
         };
      case AvailableOrdersStatus.READY_TO_SCHEDULE:
         return {
            color: '#effddc',
            label: 'COMPONENT_ORDERS.READY_TO_SCHEDULE',
         };
      case AvailableOrdersStatus.SCHEDULED:
         return {
            color: themeColors.success.background,
            label: 'COMPONENT_ORDERS.SCHEDULED',
         };
      case AvailableOrdersStatus.DELIVERED:
         return {
            color: themeColors.error.background,
            label: 'COMPONENT_ORDERS.DELIVERED',
         };
      case AvailableOrdersStatus.IN_SCHEDULE:
         return {
            color: '#effddc',
            label: 'COMPONENT_ORDERS.IN_SCHEDULE',
         };
      default:
         return {
            color: themeColors.neutral.background,
            label: 'COMPONENT_ORDERS.INITIAL',
         };
   }
};

export const initialNavigationRoleBased = (USER: IProfile | undefined) => {
   const isAdmin = USER?.roles.includes('ADMIN');
   const isClientUser = USER?.roles.includes('CLIENT_USER');
   const isConcierge = USER?.roles.includes('CONCIERGE');

   if (isAdmin) return NAVIGATION_PATHS.INITIAL_ADMIN;
   if (isClientUser) return NAVIGATION_PATHS.INITIAL_CLIENT_USER;
   if (isConcierge) return NAVIGATION_PATHS.INITIAL_CONCIERGE;

   return NAVIGATION_PATHS.LOGIN;
};
