import { Box, Button, Typography } from '@mui/material';
import { IitControlledInput } from 'components/shared-components/iit-controlled-input';
import { IitModal } from 'components/shared-components/iit-modal/iit-modal';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { IPropsTypes } from './types';
import { useCallback } from 'react';
import { UserService } from 'services/user.service';
import { AlertModalSeverity } from 'utils/enums';
import { IAlertToaster } from 'utils/interfaces';
import { displayToaster } from 'utils/utils';

const RecoverPassword = ({ state, handleClose: onClose }: IPropsTypes) => {
   const { control, handleSubmit, watch, reset } = useForm();

   const handleClose = useCallback(() => {
      reset();
      onClose();
   }, [onClose, reset]);

   const handleRecoverPassword = handleSubmit(
      useCallback(
         async (data) => {
            if (!data?.email) return;

            await UserService.forgotPassword(data?.email);

            displayToaster({
               message: t('GENERAL.EMAIL_SENT_WITH_SUCCESS'),
               severity: AlertModalSeverity.SUCCESS,
            } as IAlertToaster);

            handleClose();
         },
         [handleClose],
      ),
   );

   return (
      <IitModal state={state} handleClose={handleClose}>
         <Box display="flex" flexDirection="column">
            <Typography variant="h5">{t('GENERAL.RECOVER_PASSWORD')}</Typography>
            <Typography variant="body1" marginTop={2} marginBottom={3}>
               {t('GENERAL.TO_RECOVER_PASSWORD_PLEASE_PROVIDE_AN_EMAIL')}
            </Typography>

            <IitControlledInput
               name="email"
               label={t('GENERAL.EMAIL')}
               control={control}
               fullWidth={false}
            />

            <Box display="flex" justifyContent="end">
               <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 4 }}
                  disabled={!watch('email')}
                  onClick={handleRecoverPassword}
               >
                  {t('GENERAL.RECOVER_PASSWORD')}
               </Button>
            </Box>
         </Box>
      </IitModal>
   );
};

export { RecoverPassword };
