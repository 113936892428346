export enum InputType {
   PASSWORD = 'password',
   TEXT = 'text',
   EMAIL = 'email',
   NUMBER = 'number',
   DATE = 'date',
}

export enum AvailableLanguages {
   PT = 'pt',
   EN = 'en',
}

export enum LoginFormName {
   EMAIL = 'email',
   PASSWORD = 'password',
}

export enum ComponentsSizes {
   SMALL = 'small',
   MEDIUM = 'medium',
}

export enum DataGridDensity {
   COMPACT = 'compact',
   STANDARD = 'standard',
   COMFORTABLE = 'comfortable',
}

export enum HttpClientMethods {
   GET = 'GET',
   POST = 'POST',
   PUT = 'PUT',
   DELETE = 'DELETE',
   PATCH = 'PATCH',
}

export enum HookState {
   IDLE = 'idle',
   FETCHING = 'fetching',
   FETCHED = 'fetched',
   DELETING = 'deleting',
   DELETED = 'deleted',
   CREATING = 'creating',
   CREATED = 'created',
}

export enum ColorsStatus {
   SUCCESS = 'success',
   ERROR = 'error',
   WARNING = 'warning',
   INFO = 'info',
   PRIMARY = 'primary',
   SECONDARY = 'secondary',
   DEFAULT = 'default',
}

export enum Variants {
   BUTTON = 'button',
   CAPTION = 'caption',
   H1 = 'h1',
   H2 = 'h2',
   H3 = 'h3',
   H4 = 'h4',
   H5 = 'h5',
   H6 = 'h6',
   INHERIT = 'inherit',
   SUBTITLE1 = 'subtitle1',
   SUBTITLE2 = 'subtitle2',
   BODY1 = 'body1',
   BODY2 = 'body2',
   OVERLINE = 'overline',
}

export enum AlertModalSeverity {
   ERROR = 'error',
   WARNING = 'warning',
   INFO = 'info',
   SUCCESS = 'success',
}

export enum DEFAULT_FILTERS {
   ALL = 'ALL',
}

export enum PORTAL_ROLES {
   ADMIN = 'ADMIN',
   CLIENT_USER = 'CLIENT_USER',
   CONCIERGE = 'CONCIERGE',
}

export enum REDUCE_COUNT {
   QUANTITY = 'quantity',
   SUB_TOTAL = 'subTotal',
   RATE = 'rate',
}

export enum AUTH_ENDPOINTS {
   LOGIN = '/auth/login',
   INVITE_USER = '/auth/inviteUser',
   CHANGE_PASSWORD = '/auth/change-password',
   RECOVER_PASSWORD = '/auth/recover',
   DEFINE_PASSWORD = '/auth/define-password',
}

export enum FAQS {
   NEW = 'new',
   PARENT = 'parentFaq',
   CHILDS = 'childFaqs',
}

export enum ACCOUNT_CONFIRMATION {
   CONFIRMED = 'confirmed',
   NOT_CONFIRMED = 'could_not_confirm',
}

export enum EButtonVariant {
   TEXT = 'text',
   CONTAINED = 'contained',
   OUTLINED = 'outlined',
}

export enum MEASURABLES_EVENT_TYPES {
   COUNT = 'COUNT',
   NAVIGATION = 'NAVIGATION',
   OTHER = 'OTHER',
}

export enum WEEK_DAYS {
   MONDAY = 'monday',
   TUESDAY = 'tuesday',
   WEDNESDAY = 'wednesday',
   THURSDAY = 'thursday',
   FRIDAY = 'friday',
   SATURDAY = 'saturday',
   SUNDAY = 'sunday',
}

export enum VIEW_MODES {
   WEB_VIEW_MODE = 'webViewMode',
}

export enum LANGUAGE_TRANSLATION {
   PT = 'LANGUAGES.PT',
   EN = 'LANGUAGES.EN',
}

export enum LIST_INFO_KEYS {
   STEP = 'step',
   LIMIT = 'limit',
   TOTAL = 'total',
}
