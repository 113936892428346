import React, { FC, ReactNode } from 'react';

import { Box, Modal, SxProps } from '@mui/material';

import iitModalStyles from './style';

interface IIitModalProps {
   state: boolean;
   children: ReactNode;
   handleClose: () => void;
   sx?: SxProps;
}

const IitModal: FC<IIitModalProps> = ({
   state,
   children,
   handleClose,
   sx,
}: IIitModalProps) => {
   const { root } = iitModalStyles();

   return (
      <Modal
         sx={sx}
         open={state}
         onClose={handleClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
      >
         <Box className={root}>{children}</Box>
      </Modal>
   );
};

export { IitModal };
