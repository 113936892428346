import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { themeColors } from 'style/common';

const iitModalStyles = makeStyles((theme: Theme) => ({
   root: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: themeColors.background,
      borderRadius: 5,
      padding: theme.spacing(3),
   },
}));

export default iitModalStyles;
